// Step 1: Import React
import Layout from '../components/layout/LayoutComponent'
import { getImage } from 'gatsby-plugin-image'

import { useStaticQuery, graphql, Link } from 'gatsby'
import React, { useLayoutEffect } from 'react'
import Caroucel from "../components/Caroucel"
import { ScrollMoves } from "../components/gsapFunctions"


// Step 2: Define your component
const IndexPage = () => {

  useLayoutEffect(() => {
    let moves = ScrollMoves();
    return () => {
      moves.forEach((move) => {
        move.scrollTrigger.kill();
      })
    }
  })

  const dataQuery = useStaticQuery(graphql`
      query {
        allFile(
          filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "servicios"}}
          sort: {fields: name}
        ) {
          nodes {
            childImageSharp {
                gatsbyImageData(width: 400)
            }
            name
          }
        }
      }
  
    `)
    const imagesData = dataQuery.allFile.nodes.slice(0,3).map((node) => {
      return {
          image: getImage(node),
          title: node.name
      }
  });
  return (
    <Layout pageTitle="Inicio" content='' isHome={true}>
      <>
        <div className='sm:container px-5 md:p-0 w-full flex flex-col bg-primary/0 relative z-10 backdrop-blur-sm mx-auto'>
            <div className='sm:mx-72 my-24'>
              <h3 className='font-bold text-2xl text-center move-up'>NEUROMED & CARDIOMED RICARDO Y COMPAÑIA LIMITADA<br/>(NECARMED CIA LTDA).</h3>
              <p className='text-xl text-justify mt-8 move-up'>Somos una empresa orientada al sector salud desde nuestra fundación en el año 2010. A lo largo de estos años nos hemos dedicado a la venta, comercialización, distribución, importación y exportación de Insumos, Equipos Médicos e implantes de alta especialidad,  con una clara visión de la responsabilidad social que involucra esta actividad económica.</p>
              <div className='flex justify-center my-10 move-up'>
              <Link className='font-bold text-lg md:text-2xl text-white text-center  md:px-5 md:py-2 px-3 py-1 block rounded hover:bg-secondary-dark bg-secondary ' to='/sobre_nosotros/'>Saber mas de nosotros</Link>    
              </div>
            </div>
          <Caroucel title="Nuestros Principales Servicios" dataQuery={imagesData}/>
          <div className='flex justify-center my-10'>
            <Link className='font-bold text-lg md:text-2xl text-white text-center  md:px-5 md:py-2 px-3 py-1 block rounded hover:bg-secondary-dark bg-secondary ' to='/servicios/'>Ver todos nuestros servicios</Link>
          </div>
        </div>
      </>
    </Layout>
  )
}
// Step 3: Export your component
export default IndexPage

export const Head = () => (
  <>
    <title>Inicio | NecarMed</title>
  </>
)